@if (formControl) {
  <div class="shared-form-field shared-input-control color-{{ color }} size-{{ size }}" [ngClass]="{ readonly }">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <mat-form-field appearance="outline">
      @if (prefixIcon) {
        <div matPrefix><i [essIcon]="prefixIcon"></i></div>
      }
      @if (prefixText) {
        <span matTextPrefix>{{ prefixText }}</span>
      }
      <input
        matInput
        [ngModel]="_value"
        [placeholder]="placeholder"
        [type]="type"
        [errorStateMatcher]="_errorStateMatcher"
        [disabled]="disabled"
        [readonly]="readonly"
        [max]="type === 'date' ? '9999-12-31' : null"
        (input)="_changeHandler($event)"
        (focusout)="_onTouched()"
      />
      @if (suffixIcon) {
        <div matSuffix><i [essIcon]="suffixIcon" [essIconSize]="'xl'"></i></div>
      }
      @if (suffixText) {
        <span matTextSuffix>{{ suffixText }}</span>
      }
      @if (allowReset && _value) {
        <i class="mr-3 cursor-pointer flex items-center" essIcon="close" matSuffix (click)="_reset()"></i>
      }
      <mat-hint [align]="hintAlign">{{ hint }}</mat-hint>
      <mat-error [essErrorMessage]="errors" [errorMessageDictionary]="errorMessageDictionary"></mat-error>
    </mat-form-field>
  </div>
}
