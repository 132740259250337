import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { delay, map } from 'rxjs';

import { SharedUiIconButtonComponent } from '@ess/shared/ui/button';
import { SharedUiIconDirective } from '@ess/shared/ui/icons';

@Component({
  template: ``,
})
abstract class SharedUiControlsAbstractDaterangeCalendarHeaderComponent<D> {
  protected readonly _label: Signal<string> = toSignal(
    this.__calendar.stateChanges.pipe(delay(200), map(this.__getPeriodLabel.bind(this))),
    { initialValue: '' },
  );

  protected get _monthView(): boolean {
    return this.__calendar.currentView === 'month';
  }

  constructor(
    protected readonly __calendar: MatCalendar<D>,
    protected readonly __dateAdapter: DateAdapter<D>,
  ) {}

  protected _previousMonth(): void {
    if (this.__calendar.currentView === 'month') {
      this.__calendar.activeDate = this.__dateAdapter.addCalendarMonths(this.__calendar.activeDate, -1);
    }
  }

  protected _nextMonth(): void {
    if (this.__calendar.currentView === 'month') {
      this.__calendar.activeDate = this.__dateAdapter.addCalendarMonths(this.__calendar.activeDate, 1);
    }
  }

  protected _changeViewMode(): void {
    if (this.__calendar.currentView === 'month') {
      this.__calendar.currentView = 'multi-year';
    } else {
      this.__calendar.currentView = 'month';
    }
  }

  private __getPeriodLabel(): string {
    return this.__dateAdapter.format(
      this.__calendar.activeDate,
      this.__calendar.currentView === 'year' ? { year: 'numeric' } : { year: 'numeric', month: 'long' },
    );
  }
}

@Component({
  selector: 'ess-shared-ui-controls-daterange-calendar-header-prev',
  template: `
    <div class="flex justify-between items-center gap-x-2 px-2 py-3 select-none">
      <ess-shared-ui-icon-button
        [class.opacity-0]="!_monthView"
        [icon]="'chevron_left'"
        [size]="'sm'"
        [disabled]="!_monthView"
        (click)="_previousMonth()"
      />
      <div class="flex justify-end items-center gap-x-2">
        <span class="font-medium text-base-primary">{{ _label() }}</span>
        <ess-shared-ui-icon-button
          class="duration-200"
          [ngClass]="{ 'rotate-180': !_monthView }"
          [icon]="'chevron-down'"
          (click)="_changeViewMode()"
        />
      </div>
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SharedUiIconDirective, SharedUiIconButtonComponent],
})
export class SharedUiComponentsDaterangeCalendarHeaderPreviousComponent<
  D,
> extends SharedUiControlsAbstractDaterangeCalendarHeaderComponent<D> {}

@Component({
  selector: 'ess-shared-ui-controls-daterange-calendar-header-next',
  template: `
    <div class="flex justify-between items-center gap-x-2 pl-3 pr-2 py-3 select-none">
      <div class="flex justify-end items-center gap-x-2">
        <span class="font-medium text-base-primary">{{ _label() }}</span>
        <ess-shared-ui-icon-button
          class="duration-200"
          [ngClass]="{ 'rotate-180': !_monthView }"
          [icon]="'chevron-down'"
          (click)="_changeViewMode()"
        />
      </div>
      <ess-shared-ui-icon-button
        [class.opacity-0]="!_monthView"
        [icon]="'chevron_right'"
        [size]="'sm'"
        [disabled]="!_monthView"
        (click)="_nextMonth()"
      />
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SharedUiIconDirective, SharedUiIconButtonComponent],
})
export class SharedUiComponentsDaterangeCalendarHeaderNextComponent<
  D,
> extends SharedUiControlsAbstractDaterangeCalendarHeaderComponent<D> {}
