import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, delay, filter, fromEvent } from 'rxjs';

import { SharedUiChipsExpandableFormChipsComponent } from '@ess/shared/ui/chips';
import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { EssTreeHelper } from '@ess/shared/utils/helpers';
import {
  SharedControlColor,
  SharedControlSize,
  SharedFlattenTreeElement,
  SharedFormOption,
  SharedTreeElement,
  SharedTreeNode,
} from '@ess/shared/utils/models';
import { FlattenArrayPipe } from '@ess/shared/utils/pipes';

import { SharedUiControlsCheckboxControlComponent } from '../shared-ui-controls-checkbox-control/shared-ui-controls-checkbox-control.component';
import { SharedUiControlsAbstractControlComponent } from '../shared-ui-controls-control/shared-ui-controls-control.component.abstract';
import { SharedUiControlsSelectControlComponent } from '../shared-ui-controls-select-control/shared-ui-controls-select-control.component';

const TREE_SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SharedUiControlsTreeSelectComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'ess-shared-ui-controls-tree-select',
  standalone: true,
  imports: [
    SharedUiControlsSelectControlComponent,
    MatTreeModule,
    SharedUiControlsCheckboxControlComponent,
    MatCheckboxModule,
    SharedUiIconDirective,
    SharedUiChipsExpandableFormChipsComponent,
    FlattenArrayPipe,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [TREE_SELECT_VALUE_ACCESSOR],
  templateUrl: './shared-ui-controls-tree-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiControlsTreeSelectComponent
  extends SharedUiControlsAbstractControlComponent<string[]>
  implements AfterViewInit
{
  @ViewChild('select', { read: ElementRef }) private __selectEl!: ElementRef;
  @Input() placeholder = 'Select';
  @Input() size: SharedControlSize = 's';
  @Input() color: SharedControlColor = 'grey';
  @Input() errorText: string | null = null;
  @Input() labelImage = '';

  @Input()
  set treeData(data: SharedTreeElement[] | null) {
    this._dataSource.data = data ?? [];
    this._fakeOptions = data ? EssTreeHelper.parseTreeToFormOptions(data) : [];
    this.__selectInitialData();
  }

  protected _treeFlattener: MatTreeFlattener<SharedTreeElement, SharedFlattenTreeElement> = new MatTreeFlattener<
    SharedTreeElement,
    SharedFlattenTreeElement
  >(
    this.__getTransformer(),
    (node: SharedFlattenTreeElement) => node.level,
    (node: SharedFlattenTreeElement) => node.expandable,
    (node: SharedTreeElement) => node.children,
  );
  protected _treeControl: FlatTreeControl<SharedTreeNode> = new FlatTreeControl<SharedTreeNode>(
    (node: SharedTreeNode) => node.level,
    (node: SharedTreeNode) => node.expandable,
  );
  protected _dataSource: MatTreeFlatDataSource<SharedTreeElement, SharedFlattenTreeElement> = new MatTreeFlatDataSource<
    SharedTreeElement,
    SharedFlattenTreeElement
  >(this._treeControl, this._treeFlattener, []);
  protected readonly _selection: SelectionModel<SharedTreeNode> = new SelectionModel<SharedTreeNode>(true);

  protected _fakeOptions: SharedFormOption<string>[] = [];
  protected _selectError: ValidationErrors | null = null;

  private __emitSelectionChange = true;

  constructor(private __cdr: ChangeDetectorRef) {
    super(__cdr);
    this.__changeHandler();
  }

  @Input() valueErrorMapper = (v: SharedFormOption<string>): boolean => false;
  @Input() valueTooltipMapper = (v: SharedFormOption<string>): string => v.tooltip ?? v.name;
  @Input() valueLabelMapper = (v: SharedFormOption<string>): string => v.name;

  ngAfterViewInit(): void {
    this.__handleFocusOutEvent();
    this.__initTouchedChangeSub();
  }

  override writeValue(value: string[] | null): void {
    super.writeValue(value);
    this.__selectInitialData();
  }

  protected _hasChild: (_: number, node: SharedTreeNode) => boolean = (_: number, node: SharedTreeNode) =>
    node.expandable;
  protected _getLevel: (node: SharedTreeNode) => number = (node: SharedTreeNode) => node.level;

  protected _leafNodeSelectionToggle(node: SharedTreeNode): void {
    this._selection.toggle(node);
    this.__checkAllParentsSelection(node);
  }

  protected _descendantsAllSelected(node: SharedTreeNode): boolean {
    const descendants = this._treeControl.getDescendants(node);
    return (
      descendants.length > 0 &&
      descendants.every((child) => {
        return this._selection.isSelected(child);
      })
    );
  }

  protected _descendantsPartiallySelected(node: SharedTreeNode): boolean {
    const descendants = this._treeControl.getDescendants(node);
    const result = descendants.filter((node) => !node.expandable).some((child) => this._selection.isSelected(child));
    return result && !this._descendantsAllSelected(node);
  }

  protected _nodeSelectionToggle(node: SharedTreeNode): void {
    this._selection.toggle(node);
    const descendants = this._treeControl.getDescendants(node);
    this._selection.isSelected(node)
      ? this._selection.select(...descendants) && this._treeControl.expand(node)
      : this._selection.deselect(...descendants);

    descendants.forEach((child) => {
      this._selection.isSelected(child);
      this._treeControl.expand(child);
    });
    this.__checkAllParentsSelection(node);
  }

  protected _isSelected(id: string): boolean {
    return !!this._selection.selected.find((node) => node.id === id);
  }

  protected _removeNode(option: SharedFormOption<string>): void {
    if (this._value) {
      const value = [...this._value].filter((id) => id !== option.value);
      this._updateValue(value);
    }
  }

  private __checkAllParentsSelection(node: SharedTreeNode): void {
    let parent: SharedTreeNode | null = this.__getParentNode(node);
    while (parent) {
      this.__checkRootNodeSelection(parent);
      parent = this.__getParentNode(parent);
    }
  }

  private __checkRootNodeSelection(node: SharedTreeNode): void {
    const nodeSelected = this._selection.isSelected(node);
    const descendants = this._treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this._selection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this._selection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this._selection.select(node);
    }
  }

  private __getParentNode(node: SharedTreeNode): SharedTreeNode | null {
    const currentLevel = this._getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this._treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this._treeControl.dataNodes[i];

      if (this._getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  private __handleFocusOutEvent(): void {
    fromEvent(this.__selectEl.nativeElement, 'focusout')
      .pipe(delay(100), untilDestroyed(this))
      .subscribe(() => this._touchedChanges$.next(true));
  }

  private __initTouchedChangeSub(): void {
    this._touchedChanges$
      .pipe(
        filter((touched) => touched),
        untilDestroyed(this),
      )
      .subscribe(() => this.__setRequiredError(this._value));
  }

  private __changeHandler(): void {
    this._selection.changed
      .pipe(
        filter(() => this.__emitSelectionChange),
        debounceTime(100),
        untilDestroyed(this),
      )
      .subscribe(() => {
        let value: string[] = this._value ? [...this._value] : [];
        value = value.filter((id) => !this._fakeOptions.some((option) => option.value === id));
        value = [...this._selection.selected.filter((node) => !node.expandable).map((node) => node.id), ...value];
        this._updateValue(value);
      });
  }

  private __selectInitialData(): void {
    this.__emitSelectionChange = false;
    this._selection.clear();

    const allNodes = this._treeControl.dataNodes;
    const selectedNodes = allNodes.filter((node) => this._value?.includes(node.id));
    selectedNodes.forEach((node) => {
      const parentNode = this.__getParentNode(node);
      if (parentNode && !this._selection.isSelected(parentNode)) {
        this._selection.select(parentNode);
      }
    });

    this._selection.select(...selectedNodes);
    allNodes.forEach((node) => {
      if (this._descendantsPartiallySelected(node) || this._descendantsAllSelected(node)) {
        this._treeControl.expand(node);
      }
    });
    this.__emitSelectionChange = true;
  }

  private __setRequiredError(value: string[] | null): void {
    this._selectError =
      value && value.length
        ? null
        : this.disabled
          ? null
          : this.formControl?.hasValidator(Validators.required)
            ? { treeSelectError: this.errorText }
            : null;
    this.__cdr.detectChanges();
  }

  private __getTransformer() {
    return (node: SharedTreeElement, level: number) => {
      return {
        expandable: !!node.children && node.children.length > 0,
        name: node.label,
        level: level,
        id: node.id,
        path: node.path,
      };
    };
  }
}
