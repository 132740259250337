@if (formControl && options) {
  <div class="shared-form-field shared-button-toggle size-{{ size }}">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    @if (formControl && options.length) {
      <mat-button-toggle-group
        [disabled]="disabled || readonly"
        [ngModel]="_value"
        [class.invalid]="errors"
        [ngClass]="{ readonly }"
        (change)="_changeHandler($event)"
      >
        @for (option of options; track option) {
          <mat-button-toggle
            [value]="option.value"
            [disabled]="!!option.disabled"
            [class.mat-button-toggle-checked]="option.value === _value"
          >
            {{ option.name }}
          </mat-button-toggle>
        }
      </mat-button-toggle-group>
    }
    @if (bottomBarEnabled) {
      <div class="h-5">
        @if (!errors && hint) {
          <mat-hint class="shared-form-field-hint">{{ hint }}</mat-hint>
        }
        @if (errors) {
          <mat-error class="shared-form-field-error" [essErrorMessage]="errors"></mat-error>
        }
      </div>
    }
  </div>
}
