@if (formControl && options) {
  <div class="shared-form-field shared-input-control color-{{ color }} size-{{ size }}" [ngClass]="{ readonly }">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <mat-form-field appearance="outline">
      <input
        matInput
        [ngModel]="_value"
        [placeholder]="placeholder"
        [type]="type"
        [errorStateMatcher]="_errorStateMatcher"
        [disabled]="disabled"
        [matAutocomplete]="auto"
        [readonly]="readonly"
        (input)="_changeHandler($event)"
        (focusout)="_onTouched()"
      />
      @if (suffixIcon) {
        <div matSuffix><i [essIcon]="suffixIcon" [essIconSize]="'xl'"></i></div>
      }
      <mat-hint [align]="hintAlign">{{ hint }}</mat-hint>
      <mat-error [essErrorMessage]="errors" [errorMessageDictionary]="errorMessageDictionary"></mat-error>
    </mat-form-field>
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
      [displayWith]="_displayFn"
      [class]="'shared-select-dropdown'"
      (optionSelected)="_optionSelected($event)"
    >
      @for (option of _filteredOptions$ | async; track option) {
        <mat-option [value]="option" [disabled]="!!option.disabled">
          <span>{{ option.name }}</span>
        </mat-option>
      }
    </mat-autocomplete>
  </div>
}
