@if (formControl) {
  <div>
    <mat-checkbox
      class="shared-checkbox size-{{ size }} color-{{ color }}"
      [ngModel]="_value"
      [disabled]="disabled"
      [disableRipple]="true"
      (change)="_changeHandler($event)"
      >{{ label }}</mat-checkbox
    >
  </div>
}
